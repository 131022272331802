<template>
  <div class="animated fadeIn">

    <b-row>

      <b-col sm="12">

        <b-card>

          <form @submit.stop.prevent="Submit">

            <b-row>

              <b-col>
                  <label class="">Searching for:</label>
                  <b-form-select v-model="search_type" :options="searchoptions"></b-form-select>
                  <b-form-invalid-feedback v-if="error">This is a required field.</b-form-invalid-feedback>
              </b-col>

            </b-row>

            <div slot="footer" class="mt-3">
              <b-button type="submit" size="md" variant="success" class="pull-right">
                Submit
              </b-button>
            </div>

          </form>

        </b-card>

      </b-col>

    </b-row>

      <!--

      <b-col lg="4">
        <div class="card bg-light py-4 text-center">
          <b-button :to="{ name: 'Search Claim' }" variant="outline-dark" class="mt-3 mx-5">Search Claim</b-button>
        </div>
      </b-col>

      <b-col lg="4">
        <div class="card bg-light py-4 text-center">
          <b-button :to="{ name: 'Search Policy' }" variant="outline-dark" class="mt-3 mx-5">Search Policy</b-button>
        </div>
      </b-col>

      <b-col lg="4">
        <div class="card bg-light py-4 text-center">
          <b-button :to="{ name: 'Search Customer' }" variant="outline-dark" class="mt-3 mx-5">Search Customer</b-button>
        </div>
      </b-col>

      <b-col lg="4">
        <div class="card bg-light py-4 text-center">
          <b-button :to="{ name: 'Search Vet' }" variant="outline-dark" class="mt-3 mx-5">Search Vet</b-button>
        </div>
      </b-col>

      <b-col lg="4">
        <div class="card bg-light py-4 text-center">
          <b-button :to="{ name: 'Search Complaint' }" variant="outline-dark" class="mt-3 mx-5">Search Complaint</b-button>
        </div>
      </b-col>

      -->

    </b-row><!--/.row-->

  </div>

</template>

<script>

export default {
  name: 'Search',
  data: () => {
    return {
      search_type: null,
      searchoptions: [
        {value: null, text: 'Please select'},
        {value: 'claim', text: 'Claim'},
        {value: 'policy', text: 'Policy'},
        {value: 'customer', text: 'Customer'},
        {value: 'vet', text: 'Vet'},
        {value: 'complaint', text: 'Complaint'},
      ],
      error: false,
      submitted: false,
    }
  },
  methods: {
    Submit () {
      this.submitted = true
      if (!this.search_type) {
        this.error = true
      }
      if (this.search_type === 'claim') {
        this.$router.replace({ name: 'Search Claim' })
      }
      if (this.search_type === 'policy') {
        this.$router.replace({ name: 'Search Policy' })
      }
      if (this.search_type === 'customer') {
        this.$router.replace({ name: 'Search Customer' })
      }
      if (this.search_type === 'vet') {
        this.$router.replace({ name: 'Search Vet' })
      }
      if (this.search_type === 'complaint') {
        this.$router.replace({ name: 'Search Complaint' })
      }
    }
  }
}
</script>
